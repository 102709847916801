import React from 'react';
import { Menu, X, Activity } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <nav className="fixed top-0 w-full bg-background/90 backdrop-blur-md z-50 border-b border-accent/10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center gap-2">
            <Activity className="text-accent" size={24} />
            <span className="text-xl font-bold text-accent">ResQ</span>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center gap-8">
            <a href="#features" className="text-gray-300 hover:text-accent transition-colors">Features</a>
            <a href="#proof" className="text-gray-300 hover:text-accent transition-colors">Impact</a>
            <a href="#pricing" className="text-gray-300 hover:text-accent transition-colors">Pricing</a>
            <button 
              onClick={() => navigate('/assistant')} 
              className="btn btn-outline"
            >
              Access Terminal
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button className="md:hidden text-accent" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-secondary border-t border-accent/10">
          <div className="px-4 py-2 space-y-2">
            <a href="#features" className="block py-2 text-gray-300 hover:text-accent">Features</a>
            <a href="#proof" className="block py-2 text-gray-300 hover:text-accent">Impact</a>
            <a href="#pricing" className="block py-2 text-gray-300 hover:text-accent">Pricing</a>
            <button 
              onClick={() => navigate('/assistant')} 
              className="btn btn-outline w-full"
            >
              Access Terminal
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};