import React from 'react';
import { Check, Zap } from 'lucide-react';

const plans = [
  {
    name: 'Free',
    price: '0',
    description: 'Basic emergency guidance and first aid',
    features: [
      'Basic emergency protocols',
      'First aid instructions',
      'Public emergency contacts',
      'Community support',
    ],
  },
  {
    name: 'Pro',
    price: '9.99',
    description: 'Advanced features for medical professionals',
    features: [
      'All Free features',
      'Advanced medical protocols',
      'Offline access',
      'Priority response',
      'Custom emergency contacts',
      '24/7 professional support',
    ],
    popular: true,
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    description: 'Custom solutions for medical facilities',
    features: [
      'All Pro features',
      'Custom protocols',
      'API access',
      'Team management',
      'Analytics dashboard',
      'Dedicated support',
      'SLA guarantee',
    ],
  },
];

export const Pricing = () => {
  return (
    <div id="pricing" className="py-16 bg-secondary">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Emergency Response Plans</h2>
          <p className="text-gray-400">Choose the plan that fits your needs</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`terminal-card relative ${
                plan.popular ? 'border-accent' : ''
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                  <div className="bg-accent text-background text-sm px-4 py-1 rounded-full flex items-center gap-1">
                    <Zap size={14} />
                    Most Popular
                  </div>
                </div>
              )}

              <div className="text-center mb-6">
                <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                <div className="text-4xl font-bold text-accent mb-2">
                  {typeof plan.price === 'number' ? `$${plan.price}` : plan.price}
                  {typeof plan.price === 'number' && (
                    <span className="text-sm text-gray-400">/month</span>
                  )}
                </div>
                <p className="text-gray-400">{plan.description}</p>
              </div>

              <div className="space-y-4">
                {plan.features.map((feature) => (
                  <div key={feature} className="flex items-center gap-2">
                    <Check className="text-accent" size={18} />
                    <span className="text-sm text-gray-300">{feature}</span>
                  </div>
                ))}
              </div>

              <button
                className={`w-full mt-8 btn ${
                  plan.popular ? 'btn-primary' : 'btn-outline'
                }`}
              >
                {plan.name === 'Enterprise' ? 'Contact Sales →' : 'Get Started →'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};