import React from 'react';
import { Bot, Shield, Activity, MapPin } from 'lucide-react';

const features = [
  {
    icon: Bot,
    title: 'Emergency Response',
    description: '24/7 AI guidance for medical emergencies with instant response protocols.',
  },
  {
    icon: Shield,
    title: 'First Aid Training',
    description: 'Interactive simulations and step-by-step emergency protocols.',
  },
  {
    icon: Activity,
    title: 'Medical Knowledge',
    description: 'Evidence-based emergency care instructions verified by professionals.',
  },
  {
    icon: MapPin,
    title: 'Location Services',
    description: 'Instant connection with nearby emergency services and facilities.',
  },
];

export const Features = () => {
  return (
    <div id="features" className="py-16 bg-tertiary">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Emergency Response Features</h2>
          <p className="text-gray-400">Advanced capabilities for critical situations</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature) => (
            <div key={feature.title} className="terminal-card hover:border-accent transition-colors">
              <feature.icon className="text-accent mb-4" size={32} />
              <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};