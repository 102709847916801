import React, { useState, useEffect } from 'react';
import { X, MessageSquare, Bot, ShoppingBag, AlertCircle } from 'lucide-react';
import { Notes } from './Notes';
import { Transcriber } from './Transcriber';
import { EmergencyKit } from './EmergencyKit';
import { EmergencyButton } from './EmergencyButton';
import { useAuthStore } from '../../store/authStore';

interface DashboardProps {
  onClose: () => void;
}

export const Dashboard: React.FC<DashboardProps> = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState<'ai' | 'notes' | 'shop'>('ai');
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const { user } = useAuthStore();

  useEffect(() => {
    // Check if user is authenticated
    if (!user) {
      onClose();
    }
  }, [user, onClose]);

  return (
    <div className="fixed inset-0 bg-[#000913] flex flex-col">
      {/* Header */}
      <div className="bg-[#001219] border-b border-accent/20 p-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex items-center gap-6">
            <h1 className="text-accent font-bold text-xl">ResQ Dashboard</h1>
            <div className="flex gap-4">
              <button
                onClick={() => setActiveTab('ai')}
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors ${
                  activeTab === 'ai'
                    ? 'text-accent bg-accent/10'
                    : 'text-gray-400 hover:text-accent hover:bg-accent/5'
                }`}
              >
                <Bot size={20} />
                AI Assistant
              </button>
              <button
                onClick={() => setActiveTab('notes')}
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors ${
                  activeTab === 'notes'
                    ? 'text-accent bg-accent/10'
                    : 'text-gray-400 hover:text-accent hover:bg-accent/5'
                }`}
              >
                <MessageSquare size={20} />
                Notes
              </button>
              <button
                onClick={() => setActiveTab('shop')}
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors ${
                  activeTab === 'shop'
                    ? 'text-accent bg-accent/10'
                    : 'text-gray-400 hover:text-accent hover:bg-accent/5'
                }`}
              >
                <ShoppingBag size={20} />
                Emergency Kit
              </button>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <EmergencyButton />
            <button
              onClick={onClose}
              className="text-accent hover:text-accent/80 transition-colors"
            >
              <X size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 max-w-7xl mx-auto w-full p-4">
        {activeTab === 'ai' ? (
          <div className="grid grid-cols-3 gap-6 h-full">
            <div className="col-span-2 h-full rounded-lg overflow-hidden border border-accent/20 relative">
              {!iframeLoaded && (
                <div className="absolute inset-0 bg-secondary flex items-center justify-center">
                  <div className="text-accent">Loading AI Assistant...</div>
                </div>
              )}
              <iframe
                src="https://vapi.ai?demo=true&shareKey=2b8b8ff0-de39-4be4-aa24-f3b8dc5ea1e8&assistantId=ee2f762e-b4ee-4d9f-8724-1108196cc3a3"
                className="w-full h-full"
                allow="camera;microphone"
                title="AI Assistant"
                onLoad={() => setIframeLoaded(true)}
              />
            </div>
            <Transcriber />
          </div>
        ) : activeTab === 'notes' ? (
          <Notes />
        ) : (
          <EmergencyKit />
        )}
      </div>
    </div>
  );
};