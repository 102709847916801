import React from 'react';
import { Heart, Shield, Star, Activity, Github, Linkedin, Twitter } from 'lucide-react';

export const Footer = () => {
  return (
    <footer className="bg-secondary/95 border-t border-accent/10">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* Brand Section */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <Activity className="text-accent" size={24} />
              <span className="text-2xl font-bold text-accent">ResQ</span>
            </div>
            <p className="text-gray-400 text-sm">
              The Ultimate Emergency Management AI Assistant, trusted by medical professionals worldwide.
            </p>
            <div className="flex items-center gap-4 text-accent/60">
              <a href="https://twitter.com/orocarelive" target="_blank" rel="noopener noreferrer" className="hover:text-accent transition-colors">
                <Twitter size={20} />
              </a>
              <a href="https://www.linkedin.com/company/orocare" target="_blank" rel="noopener noreferrer" className="hover:text-accent transition-colors">
                <Linkedin size={20} />
              </a>
              <a href="https://github.com/orocare" target="_blank" rel="noopener noreferrer" className="hover:text-accent transition-colors">
                <Github size={20} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-accent font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a href="#features" className="hover:text-accent transition-colors">Features</a>
              </li>
              <li>
                <a href="#pricing" className="hover:text-accent transition-colors">Pricing</a>
              </li>
              <li>
                <a href="#testimonials" className="hover:text-accent transition-colors">Testimonials</a>
              </li>
              <li>
                <a href="#contact" className="hover:text-accent transition-colors">Contact</a>
              </li>
            </ul>
          </div>

          {/* Success Metrics */}
          <div>
            <h3 className="text-accent font-bold mb-4">Impact</h3>
            <ul className="space-y-3">
              <li className="flex items-center gap-2 text-gray-400">
                <Heart size={16} className="text-accent" />
                <span>100,000+ Lives Impacted</span>
              </li>
              <li className="flex items-center gap-2 text-gray-400">
                <Shield size={16} className="text-accent" />
                <span>99.9% Response Rate</span>
              </li>
              <li className="flex items-center gap-2 text-gray-400">
                <Star size={16} className="text-accent" />
                <span>4.9/5 User Rating</span>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-accent font-bold mb-4">Contact</h3>
            <ul className="space-y-2 text-gray-400">
              <li>support@orocare.live</li>
              <li>+1 (888) RESQ-911</li>
              <li>Available 24/7</li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-12 pt-8 border-t border-accent/10">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} OroCare Live. All rights reserved.
            </p>
            <div className="flex gap-6 text-sm text-gray-400">
              <a href="https://orocare.live/privacy" target="_blank" rel="noopener noreferrer" className="hover:text-accent transition-colors">Privacy Policy</a>
              <a href="https://orocare.live/terms" target="_blank" rel="noopener noreferrer" className="hover:text-accent transition-colors">Terms of Service</a>
              <a href="https://orocare.live/cookies" target="_blank" rel="noopener noreferrer" className="hover:text-accent transition-colors">Cookie Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};