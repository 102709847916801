import React from 'react';
import { X } from 'lucide-react';

interface AIAssistantProps {
  onClose: () => void;
}

export const AIAssistant: React.FC<AIAssistantProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-background flex flex-col">
      <div className="p-4 flex justify-between items-center border-b border-accent/20">
        <h1 className="text-accent font-bold text-xl">ResQ AI Assistant</h1>
        <button 
          onClick={onClose}
          className="text-accent hover:text-accent/80 transition-colors"
        >
          <X size={24} />
        </button>
      </div>
      <div className="flex-1">
        <iframe
          src="https://vapi.ai?demo=true&shareKey=2b8b8ff0-de39-4be4-aa24-f3b8dc5ea1e8&assistantId=ee2f762e-b4ee-4d9f-8724-1108196cc3a3"
          className="w-full h-full border-0"
          allow="camera;microphone"
          title="AI Assistant"
        />
      </div>
    </div>
  );
};