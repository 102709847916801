import React from 'react';
import { Activity } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ChatbotPreview } from './ChatbotPreview';

export const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="pt-24 pb-16 relative overflow-hidden">
      {/* Pulse Animation Background */}
      <div className="absolute inset-0 flex items-center justify-center opacity-10">
        <div className="w-96 h-96 bg-accent rounded-full animate-pulse"></div>
      </div>

      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="relative z-10">
            <div className="flex items-center gap-2 mb-6">
              <Activity className="text-accent animate-pulse" size={24} />
              <span className="text-accent font-mono">EMERGENCY RESPONSE SYSTEM</span>
            </div>
            
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              Your AI Emergency
              <span className="text-accent block mt-2">Medical Assistant</span>
            </h1>
            
            <p className="text-gray-400 text-lg mb-8">
              Get instant guidance for medical emergencies and first aid.
              AI-powered response system available 24/7.
            </p>

            <div className="flex flex-col sm:flex-row gap-4 mb-8">
              <button 
                onClick={() => navigate('/assistant')} 
                className="btn btn-primary"
              >
                Access ResQ Now →
              </button>
              <button className="btn btn-outline">
                View Demo
              </button>
            </div>

            <div className="flex items-center gap-4 text-sm text-gray-400">
              <div className="flex -space-x-2">
                {[...Array(4)].map((_, i) => (
                  <div key={i} className="w-8 h-8 rounded-full bg-accent/20 border-2 border-background" />
                ))}
              </div>
              <span>Trusted by 10,000+ medical professionals</span>
            </div>
          </div>

          <div className="relative">
            <ChatbotPreview />
          </div>
        </div>
      </div>
    </div>
  );
};