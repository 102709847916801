import React from 'react';
import { Quote, Shield, Award, Clock } from 'lucide-react';

const testimonials = [
  {
    quote: "ResQ's AI has revolutionized our emergency response protocols. The accuracy and speed are unprecedented.",
    name: "Dr. Sarah Chen",
    title: "Emergency Medicine Director, NYC Health",
    image: "https://images.unsplash.com/photo-1559839734-2b71ea197ec2?auto=format&fit=crop&q=80&w=100&h=100"
  },
  {
    quote: "As a first responder, having ResQ's guidance in critical situations has been invaluable. It's like having an expert consultant always available.",
    name: "James Rodriguez",
    title: "Chief Paramedic, LA Fire Department",
    image: "https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?auto=format&fit=crop&q=80&w=100&h=100"
  },
  {
    quote: "The integration of AI in emergency care is the future, and ResQ is leading the way with its innovative approach.",
    name: "Dr. Michael Patel",
    title: "Head of Emergency Medicine, Mayo Clinic",
    image: "https://images.unsplash.com/photo-1537368910025-700350fe46c7?auto=format&fit=crop&q=80&w=100&h=100"
  }
];

const metrics = [
  {
    icon: Shield,
    value: "99.9%",
    label: "Accuracy Rate"
  },
  {
    icon: Clock,
    value: "< 1s",
    label: "Response Time"
  },
  {
    icon: Award,
    value: "50+",
    label: "Medical Partners"
  }
];

export const SocialProof = () => {
  return (
    <section className="py-24 bg-secondary/90" id="testimonials">
      <div className="max-w-7xl mx-auto px-4">
        {/* Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
          {metrics.map((metric) => (
            <div key={metric.label} className="terminal-card text-center group hover:border-accent transition-all duration-300">
              <metric.icon className="mx-auto text-accent mb-4" size={32} />
              <div className="text-3xl font-bold text-accent mb-2">{metric.value}</div>
              <div className="text-gray-400">{metric.label}</div>
            </div>
          ))}
        </div>

        {/* Testimonials */}
        <h2 className="text-3xl font-bold text-center mb-12">
          Trusted by <span className="text-accent">Medical Professionals</span>
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div key={testimonial.name} className="terminal-card group hover:border-accent transition-all duration-300">
              <Quote className="text-accent mb-4 opacity-50 group-hover:opacity-100 transition-opacity" size={32} />
              <p className="text-gray-300 mb-6">{testimonial.quote}</p>
              <div className="flex items-center gap-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-12 h-12 rounded-full object-cover border-2 border-accent/20"
                />
                <div>
                  <h4 className="text-accent font-bold">{testimonial.name}</h4>
                  <p className="text-gray-400 text-sm">{testimonial.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Trust Badges */}
        <div className="mt-20 text-center">
          <p className="text-gray-400 mb-8">Certified & Trusted By</p>
          <div className="flex justify-center items-center gap-12 opacity-50 hover:opacity-100 transition-opacity">
            <img src="https://images.unsplash.com/photo-1505751172876-fa1923c5c528?auto=format&fit=crop&q=80&w=100" alt="Medical Association" className="h-12 grayscale hover:grayscale-0 transition-all" />
            <img src="https://images.unsplash.com/photo-1505751172876-fa1923c5c528?auto=format&fit=crop&q=80&w=100" alt="Emergency Response" className="h-12 grayscale hover:grayscale-0 transition-all" />
            <img src="https://images.unsplash.com/photo-1505751172876-fa1923c5c528?auto=format&fit=crop&q=80&w=100" alt="Healthcare Tech" className="h-12 grayscale hover:grayscale-0 transition-all" />
          </div>
        </div>
      </div>
    </section>
  );
};