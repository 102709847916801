import React, { useState, useEffect, useCallback } from 'react';
import { Bot, Send, Loader } from 'lucide-react';

const demoConversation = [
  {
    type: 'system',
    message: 'Emergency Medical AI Assistant activated. How can I help you?'
  },
  {
    type: 'user',
    message: 'Someone is having chest pain and difficulty breathing'
  },
  {
    type: 'assistant',
    message: 'Emergency Protocol Activated:\n1. Call emergency services immediately (911)\n2. Have the person sit or lie in a comfortable position\n3. Loosen any tight clothing\n4. Check if they have heart medication\n\nVital signs monitoring initiated. Continuing emergency assessment...'
  }
];

export const ChatbotPreview = () => {
  const [messages, setMessages] = useState(demoConversation.slice(0, 1));
  const [isTyping, setIsTyping] = useState(false);

  const showNextMessage = useCallback(async (index: number) => {
    if (index < demoConversation.length) {
      setIsTyping(true);
      await new Promise(resolve => setTimeout(resolve, 1500));
      setIsTyping(false);
      setMessages(prev => [...prev, demoConversation[index]]);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout;

    if (messages.length < demoConversation.length) {
      timeoutId = setTimeout(() => {
        if (mounted) {
          showNextMessage(messages.length);
        }
      }, 2000);
    }

    return () => {
      mounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [messages.length, showNextMessage]);

  return (
    <div className="terminal-card w-full max-w-md mx-auto">
      {/* Chat Header */}
      <div className="flex items-center gap-2 p-4 border-b border-accent/20">
        <div className="w-3 h-3 rounded-full bg-accent animate-pulse"></div>
        <Bot className="text-accent" size={20} />
        <span className="text-accent font-bold">ResQ Emergency AI</span>
      </div>

      {/* Chat Messages */}
      <div className="h-[300px] overflow-y-auto p-4 space-y-4">
        {messages.map((msg, idx) => (
          <div
            key={idx}
            className={`flex ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[80%] p-3 rounded-lg ${
                msg.type === 'user'
                  ? 'bg-accent text-background ml-auto'
                  : 'bg-secondary border border-accent/20'
              }`}
            >
              <pre className="whitespace-pre-wrap font-mono text-sm">
                {msg.message}
              </pre>
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="flex items-center gap-2 text-accent">
            <Loader className="animate-spin" size={16} />
            <span className="text-sm">AI Assistant is responding...</span>
          </div>
        )}
      </div>

      {/* Chat Input */}
      <div className="p-4 border-t border-accent/20">
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Describe the emergency situation..."
            className="flex-1 bg-secondary border border-accent/20 rounded-md px-4 py-2 text-white placeholder-gray-400 focus:border-accent focus:outline-none transition-colors"
            disabled
          />
          <button className="btn btn-primary p-2">
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};