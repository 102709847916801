import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/layout/Navbar';
import { Hero } from './components/home/Hero';
import { Features } from './components/home/Features';
import { Stats } from './components/home/Stats';
import { SocialProof } from './components/home/SocialProof';
import { Pricing } from './components/home/Pricing';
import { Footer } from './components/layout/Footer';
import { Dashboard } from './components/dashboard/Dashboard';
import { AIAssistant } from './components/dashboard/AIAssistant';

// Landing page component
const Landing = () => (
  <div className="min-h-screen bg-[#000913] text-white">
    <Navbar />
    <Hero />
    <Features />
    <Stats />
    <SocialProof />
    <Pricing />
    <Footer />
  </div>
);

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/assistant" element={<AIAssistant onClose={() => window.location.href = '/'} />} />
        <Route path="/dashboard" element={<Dashboard onClose={() => window.location.href = '/'} />} />
      </Routes>
    </BrowserRouter>
  );
}